import React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
    DIGICO_ASSESSMENT_ID_ALL,
    DIGICO_DEFAULT_ASSESSMENT,
} from '@lib/constants';

const HomePage: NextPage = () => {
    console.info('2023-07-01 2.XX.0 v3');
    const router = useRouter();
    router.push(
        `/assessment-tool/${DIGICO_ASSESSMENT_ID_ALL}/${DIGICO_DEFAULT_ASSESSMENT}`,
    );

    return (
        <Head>
            <title>Digico - Homepage</title>
            <meta name="description" content="Digico" />
            <link rel="icon" href="/favicon.ico" />
        </Head>
    );
};

export default HomePage;
